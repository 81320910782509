<template>
    <div class="stage-summary">
        <div class="summary">
            <div class="line">
                <div class="key">IMSI:</div>
                <div class="value">{{ deviceDetails.details.IMSI }}</div>
            </div>

            <div class="line">
                <div class="key">IMEI:</div>
                <div class="value">{{ deviceDetails.details.IMEI }}</div>
            </div>

            <div class="line">
                <div class="key">Date Created:</div>
                <div class="value">{{ formatDateNano(deviceDetails.details.CreatedAtNanos) }}</div>
            </div>

            <div class="line">
                <div class="key">Subscription Assigned To:</div>
                <div class="value">{{ deviceDetails.assignedSubscription.Name }}</div>
            </div>

        </div>

        <button class="btn btn-primary btn-small" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        deviceDetails: { type: Object, required: true },
    },

    data() {
        return {}
    },

    methods: {
        init() {
            console.log('init the summary stage...')
        },
        formatDateNano,
        confirm() {
            this.$emit('confirm')
        },
    },

    computed: {
    },
}
</script>

<style lang="scss">
.stage-summary {
    .summary {
        margin-bottom: 24px;

        .line {
            display: flex;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            .key {
                min-width: 130px;
                padding-right: 20px;
                font-size: 18px;
                font-weight: bold;
                color: var(--color-text-primary);
            }

            .value {
                color: var(--color-text-primary);
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .stage-summary {
        .summary {
            .line {
                flex-direction: column;
                font-size: 14px;
                margin-bottom: 8px;

                .key {
                    font-size: 16px;
                    padding: 0;
                    margin-bottom: 4px;
                }
            }
        }
    }
}
</style>