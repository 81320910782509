<template>
    <div class="stage-details">
        <section class="details">
            <app-error v-model="errors.show" :message="errors.message"></app-error>
            <form @submit.prevent class="form-device">
                <div class="form-group">
                    <div class="form-controls">
                        <app-input
                            v-model="device.IMSI"
                            type="number"
                            label="IMSI"
                            :error="!!errors.fields.IMSI"
                            ref="imsi"
                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-controls">
                        <app-input
                            v-model="device.IMEI"
                            type="number"
                            label="IMEI"
                            :error="!!errors.fields.IMEI"
                            ref="imei"
                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-controls">
                        <app-input v-for="(accNo, index) in device.AccessNumbers" :key="index"
                            :value="accNo"
                            @input="(val) => {updateAccessNumberAtIndex(val, index)}"
                            label="Access Number"
                            :error="!!errors.fields.IMEI"
                            ref="imei"
                        />

                        <button class="btn btn-secondary" @click="newAccessNumber">Add Access Number</button>
                    </div>
                </div>
            </form>
        </section>

        <button class="btn btn-primary btn-small btn-confirm" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appError from '@/components/app-error'

import appInput from '@/components/app-input'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        deviceDetails: { type: Object, required: true },
    },

    components: {
        appError,

        appInput,
    },

    data() {
        return {
            device: {
                IMSI: 0,
                IMEI: 0,
                AccessNumbers: [''],
                SPID: 0
            },

            errors: {
                show: false,
                message: '',
                fields: {},
            },

            processing: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        newAccessNumber () {
            this.device.AccessNumbers.push('')
        },
        updateAccessNumberAtIndex (val, index) {
            this.$set(this.device.AccessNumbers, index, val)
        },

        init() {
            console.log('cool')
        },

        confirm() {
            this.goNext()
        },

        goNext() {
            this.device.IMSI = Number(this.device.IMSI)
            this.device.IMEI = Number(this.device.IMEI)
            this.device.SPID = this.current_spid
            this.device.MtxType = 'CustDeviceExtension'

            this.processing = true
            this.$store.dispatch('api_matrixx/CreateNewMtxDevice', this.device).then((resp) => {
                this.$emit('go-next', {
                    fromStage: 'details',
                    device: resp
                })
                this.processing = false
            }).catch(error => {
                this.errors.show = true
                this.errors.message = errMessage(error)
                this.processing = false
            })
        },


        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }

            const errors = Object.keys(this.errors.fields)

            if (errors.length) {
                this.errors.show = true
                this.errors.message = this.errors.fields[ errors[0] ]
            } else {
                this.errors.show = false
            }

            return this.errors.show
        },
    },

    watch: {
        location: {
            handler() {
                this.$emit('ready', { location: this.location })
            },
            deep: true,
        },

        serviceAbilityMessage() {
            this.$emit('ready', { services: this.serviceAbilityMessage })
        },

        processing() {
            this.$emit('processing', this.processing)
        },
    },

    computed: {
        ...mapGetters({
            'current_spid': 'current_spid',
            'search_by_google': 'broadband_location_search_by_google',
            'search_by_locationsvc': 'broadband_location_search_by_locationsvc',
        }),
    },
}
</script>

<style lang="scss">
.stage-details {
    .details {
        width: 100%;
        max-width: 657px;

        .form-group {
            margin-bottom: 30px;

            &:nth-child(3) {
                .form-controls {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    $btn-max-width: 245px;

                    .app-input {
                        max-width: calc(100% - 30px - #{ $btn-max-width });
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .stage-details {
        .details {
            .form-group {
                &:nth-child(3) {
                    .form-controls {
                        .app-input {
                            max-width: 100%;

                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .stage-details {
        .details {
            .form-group {
                margin-bottom: 24px;

                &:nth-child(3) {
                    .form-controls {
                        .app-input {
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }
    }

    .stage-location {
        .services {
            flex-direction: column;
            font-size: 14px;

            .prefix {
                font-size: 16px;
                padding: 0;
            }

            .list {
                margin-top: 4px;
            }
        }
    }
}
</style>