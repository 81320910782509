<template>
    <base-layout>
        <div class="container page-matrixx-stages">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Add Device</h1>

                    <app-loader v-if="loading"></app-loader>

                    <div class="matrixx-stages">
                        <div class="stages">
                            <div class="stage" v-for="(stage, key) in stages.list" :key="key"
                                :class="{
                                    [`step-${ key }`]: true,
                                    opened: stage.name == stages.opened,
                                    completed: stage.completed,
                                    current: stage.name == stages.current,
                                }"
                            >
                                <div class="stage-head">
                                    <div class="btn btn-stage btn-stage-point">
                                        <div class="stage-circle">
                                        </div>
                                        <span></span>
                                    </div>
                                    <button class="btn btn-stage btn-stage-expand" @click="toggleStage(stage)" :disabled="!stage.ready">{{ stage.title }}</button>
                                </div>

                                <div class="stage-body">
                                    <component :is="stage.body"
                                        :device-details="deviceDetails"

                                        @ready="onStageReady"
                                        @go-next="goNext"

                                        @confirm="onConfirm"

                                        ref="stage"
                                    ></component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appLoader from '@/components/app-loader'

import dialogBroadbandAddSuccess from './dialogs/dialog-broadband-add-success'

import stageDetails from './stages/details'
import stageAssign from './stages/assign'
import stageSummary from './stages/summary'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        baseLayout,
        appLoader,
        dialogBroadbandAddSuccess,
    },

    data() {
        return {
            deviceDetails: {
                details: {},
                assignedSubscription: {}
            },

            stages: {
                opened: 'details',
                current: 'details',

                list: {
                    'details': {
                        name: 'details',
                        ready: true,
                        completed: false,
                        title: 'Details',
                        body: stageDetails,
                    },
                    
                    'assign': {
                        name: 'assign',
                        ready: false,
                        completed: false,
                        title: 'Assign to Subscription',
                        body: stageAssign,
                    },

                    'summary': {
                        name: 'summary',
                        ready: false,
                        completed: false,
                        title: 'Summary',
                        body: stageSummary,
                    },
                },
            },

            dialogs: {
                success: {
                    show: false,
                }
            },

            loading: false,
        }
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        stage() {
            return this.stages.list[ this.stages.opened ]
        },

        stages_indexes() {
            let index = 0,
                indexes = {}

            for (const key in this.stages.list) {
                indexes[ key ] = index++
            }

            return indexes
        },

        stages_sequence() {
            return Object.keys(this.stages.list)
        },
    },

    methods: {
        goNext(e) {
            if (e.fromStage === 'details') {
                this.deviceDetails.details = e.device
            } else if (e.fromStage === 'assign') {
                this.deviceDetails.assignedSubscription = e.assignedSubscription
            }

            this.stage.completed = true

            const next_stage_index = this.stages_indexes[this.stages.opened] + 1

            if (next_stage_index < this.stages_sequence.length) {
                this.stages.opened = this.stages_sequence[ next_stage_index ]
                this.stages.current = this.stages.opened

                this.$nextTick(() => {
                    this.$refs.stage[next_stage_index].init()
                })
            }
        },

        onStageReady(broadband) {
            this.toggleStageReady(true)
        },

        toggleStageReady(ready) {
            if (ready) {
                const next_stage = this.stages_sequence[ this.stages_indexes[this.stages.opened] + 1 ]

                if (next_stage) {
                    this.stages.list[ next_stage ].ready = true
                }
            } else {
                /*
                    for (let i = this.stages_indexes[this.stages.opened] + 1, len = this.stages_sequence.length; i < len; i++) {
                        const next_stage = this.stages_sequence[i]

                        this.stages.list[ next_stage ].ready = false

                        this.stages.current = this.stages.opened
                    }
                */
            }
        },

        onChangeProcessing(processing) {
            this.processing = processing
        },

        toggleStage(stage, is_not_closed) {
            if (is_not_closed) {
                this.stages.opened = stage.name
            } else {
                this.stages.opened = this.stages.opened == stage.name ? null : stage.name
            }
        },

        onConfirm(broadband) {
            this.$router.push({ name: 'matrixx-subscriptions' })
        },
    },
}
</script>

<style lang="scss">
.page-matrixx-stages {
    h1 {
        span {
            white-space: nowrap;
        }
    }
    
    .matrixx-stages {
        margin: 48px auto 0;
        padding: 24px 24px 32px;
        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-tertiary);

        /* @todo remove this "overflow: hidden;" after making for :before dynamic height */
        overflow: hidden;

        h3 {
            font-size: 32px;
            line-height: 40px;
            font-weight: bold;
        }

        .stages {
            margin: 32px 0 0 8px;
            border-radius: 0 0 $border-radius-secondary $border-radius-secondary;

            /* @todo make for :before dynamic height */
            // overflow: hidden;

            .btn-stage {
                padding: 0;

                &:not(button) {
                    cursor: default;
                }

                &.btn-stage-point {
                    width: 32px;
                    height: 32px;
                    position: relative;
                    background: transparent;
                    flex-shrink: 0;
                    margin-right: 16px;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 2px;
                        height: 1000px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        background: var(--color-stages-icons-bg);
                        transform: translate(-50%, 0);
                        pointer-events: none;
                    }

                    .stage-circle {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(0.5);

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 100%;
                        height: 100%;

                        background-color: var(--color-stages-icons-bg);

                        border: 2px solid var(--color-stages-icons-bg);

                        border-radius: 50%;

                        pointer-events: none;

                        transition: $transition-duration-primary;

                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        width: 24px;
                        height: 24px;

                        color: var(--color-stage-icon);

                        opacity: 0;
                        visibility: hidden;

                        transition: $transition-duration-primary;
                    }

                    // &:after {
                    //     // content: "";
                    //     // display: inline-block;
                    //     // width: 50%;
                    //     // height: 50%;
                    //     // border: 2px solid var(--color-stages-icons-bg);
                    //     // border-radius: 50%;
                    //     // background: var(--color-stages-icons-bg) none no-repeat center;
                    //     // box-sizing: border-box;
                    //     // position: absolute;
                    //     // top: 50%;
                    //     // left: 50%;
                    //     // transform: translate(-50%, -50%);
                    //     // pointer-events: none;
                    //     // transition: $transition-duration-primary;
                    // }

                    &.horizontal {
                        &:before {
                            width: 1000px;
                            height: 2px;
                            transform: translate(0, -50%);
                        }
                    }

                    &[disabled] {
                        opacity: 1;
                    }
                }

                &.btn-stage-expand {
                    width: auto;
                    height: 100%;
                    background: transparent;
                    font-size: 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: var(--color-stages-title);
                    text-transform: $stages-btn-text-transform;
                    transition: $transition-duration-primary;

                    &[disabled] {
                        color: var(--color-stages-title);
                        cursor: default;
                    }
                }
            }

            .stage {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;

                    .btn-stage-point {
                        &:before {
                            background: var(--color-component-bg-primary);
                        }
                    }
                }

                .stage-head {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    i {
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        background: none no-repeat center;
                        background-size: contain;
                        flex-shrink: 0;
                    }
                }

                .stage-body {
                    display: none;
                    padding: 24px 0 0 #{ 32px + 16px };
                }

                &.opened {
                    .stage-head {
                        .btn-stage-expand {
                            color: var(--color-stages-title-active);

                            &:after {
                                transform: rotateX(180deg);
                            }
                        }
                    }

                    .stage-body {
                        display: block;
                    }
                }

                &.completed {
                    .btn-stage {
                        &.btn-stage-point {
                            &:before {
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            .stage-circle {
                                transform: translate(-50%, -50%) scale(1);
                                border: 2px solid var(--color-stages-icons-bg-active);
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            span {
                                opacity: 1;
                                visibility: visible;

                                &:before {
                                    color: var(--color-stage-icon-active);
                                }
                            }

                            // &:after {
                            //     width: 100%;
                            //     height: 100%;
                            //     border: 2px solid var(--color-stages-icons-bg-active);
                            //     background-color: var(--color-stages-icons-bg-active);
                            // }
                        }

                        &.btn-stage-expand {
                            &:hover {
                                color: var(--color-stages-title-active);
                            }
                        }
                    }
                }

                &.current {
                    .btn-stage {
                        &.btn-stage-point {
                            .stage-circle {
                                transform: translate(-50%, -50%) scale(1);
                                border: 2px solid var(--color-stages-icons-bg-active);
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            span {
                                opacity: 1;
                                visibility: visible;

                                &:before {
                                    color: var(--color-stage-icon-active);
                                }
                            }

                            // &:after {
                            //     width: 100%;
                            //     height: 100%;
                            //     border: 2px solid var(--color-stages-icons-bg-active);
                            //     background-color: var(--color-stages-icons-bg-active);
                            // }
                        }
                        
                        &.btn-stage-expand {
                            color: var(--color-stages-title-active);
                        }
                    }
                }
            }



            .stage {
                &.step-sp-details {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    // @include icon-before($icon-map-mark);
                                }
                            }
                        }
                    }
                }

                &.step-choose-plan {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    // @include icon-before($icon-plan);
                                }
                            }
                        }
                    }
                }

                &.step-summary {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    // @include icon-before($icon-summary);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-matrixx-stages {
        .matrixx-stages {
            padding: 24px 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-matrixx-stages {
        .matrixx-stages {
            margin-top: 32px;
            padding: 16px 16px 16px 8px;

            h3 {
                font-size: 24px;
                line-height: 32px;
            }

            .stages {
                margin: 20px 0 0 0;

                .btn-stage {
                    &.btn-stage-point {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;

                        span {
                            transform: translate(-50%, -50%) scale(0.8);
                        }

                        // &:after {
                        //     background-size: 70%;
                        // }
                    }

                    &.btn-stage-expand {
                        font-size: 16px;
                    }
                }

                .stage {
                    .stage-body {
                        padding-left: 26px;

                        .app-input {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>